<div *ngIf="isLoadingRequired">
  <app-loading-animation></app-loading-animation>
</div>

<section>
  <div class="card">
    <div
      class="card-header d-flex justify-content-between align-items-center"
      style="background-color: rgba(13, 110, 253, 0.2) !important"
    >
    <div class="d-flex align-items-center">
    <svg height="25" width="10" style="margin-right: 5px">
      <line
        x1="5"
        y1="0"
        x2="5"
        y2="50"
        style="stroke: rgb(0, 0, 0); stroke-width: 4"
      />
    </svg>
      <p class="my-1 fs-6 fw-bolder">Employee Data</p>
    </div>
      <button
        class="close-icon fs-6 border-0 text-danger fw-bold bg-transparent"
        (click)="onNoClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="card-body p-2">
      <div mat-dialog-content>
        <ng-container *ngIf="isDataAvailable(); else noData">
          <div
        class="d-flex justify-content-between align-items-center mb-3 position-relative"
      >
        <input
          type="text"
          style="width: 40%"
          class="form-control pl-4"
          placeholder="Search..."
          [(ngModel)]="searchText"
          (input)="filterEmployeeChart()"
        /><mat-icon class="search-icon position-absolute">search</mat-icon>
        <button
          class="btn btn-sm btn-primary"
          (click)="downloadExcel()"
        >
          <mat-icon class="mat_icon_white outlined-circle">save_alt</mat-icon>
          Download Excel
        </button>
      </div>
          <div class="responsive-table">
            <table class="table table-bordered table-hover mb-0"
            matSort
          (matSortChange)="sortData($event)">
              <thead class="table-secondary">
                <tr>
                  <th mat-sort-header="fullName">Employee Name</th>
                  <th mat-sort-header="mobileNo">Mobile No</th>
                  <th mat-sort-header="emailId">Email Id</th>
                  <th mat-sort-header="compSequenceTypeName">Document</th>
                  <th mat-sort-header="expiryDate">Expiry Date</th>
                  <th>Resume</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let employee of filteredEmployeeChart">
                  <td>{{ getFullName(employee) }}</td>
                  <td>{{ employee.mobileNo }}</td>
                  <td>{{ employee.emailId }}</td>
                  <td>{{ employee.compSequenceTypeName }}</td>
                  <td>{{ employee.expiryDate | date : "dd-MM-yyyy" }}</td>
                  <td class="text-center">
                    <ng-container
                      *ngIf="employee.resumeFileExtensionTypeName !== 'None'"
                    >
                      <button class="border-0 text-primary bg-transparent">
                        <img
                          matTooltip="Document Preview"
                          [src]="
                            getIconUrl(employee.resumeFileExtensionTypeName)
                          "
                          alt="Document Icon"
                          width="30"
                          (click)="downloadFile(employee)"
                        />
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="employee.resumeFileExtensionTypeName === 'None'"
                    >
                      <span class="text-primary">-No File-</span>
                    </ng-container>
                  </td>
                  <td class="text-center">
                    <mat-icon
                      class="text-primary mat_icon_view ion-icon"
                      matTooltip="View Profile"
                      (click)="onViewEmployee(employee)"
                      >remove_red_eye</mat-icon
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer bg-transparent">
            <mat-paginator
              [length]="totalItems"
              [pageSize]="pageSize"
              [pageIndex]="currentPageIndex"
              (page)="onPageChange($event)"
              [pageSizeOptions]="[5, 10, 15, 20, 25]"
            >
            </mat-paginator>
          </div>
        </ng-container>
        <ng-template #noData>No data available.</ng-template>
      </div>
    </div>
  </div>
</section>
