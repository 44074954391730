<section class="d-flex">
  <div>
    <mat-icon
      matTooltip="Chat"
      class="notification-icon"
      [matBadge]="messageCount > 0 ? messageCount : null"
      matBadgeColor="accent"
      [matMenuTriggerFor]="chatMenu"
      (click)="toggleChatMenu()"
    >
      chat
    </mat-icon>
    <mat-menu #chatMenu="matMenu" yPosition="below">
      <div class="px-3 mb-2">
        <p class="chat-header">Messages</p>
      </div>
      <div *ngIf="chatAlerts.length; else noDataTemplate">
        <div
          class="px-3"
          *ngFor="let alert of chatAlerts"
          (click)="navigateToChat(alert.taskInfoId, alert.alertType)"
          style="cursor: pointer"
        >
          <p class="mb-0 py-2">
            <ng-container *ngIf="alert.alertType === 2; else statusMessage">
              <span class="font-weight-bold">Messages:</span>
              Task Name:
              <span class="text-primary notification-document">{{
                alert.taskName
              }}</span>
              <span *ngIf="alert.alertCount > 0" class="text-danger">
                ({{ alert.alertCount }} unread messages)
              </span>
            </ng-container>
            <ng-template #statusMessage>
              <span class="font-weight-bold">Status:</span>
              Task Name:
              <span class="text-primary notification-document">{{
                alert.taskName
              }}</span>
              <span *ngIf="alert.alertCount > 0" class="text-danger">
                ({{ alert.alertCount }} Unread status)
              </span>
            </ng-template>
          </p>
        </div>
      </div>
    </mat-menu>
  </div>
  <div>
    <mat-icon
      matTooltip="Notification"
      class="notification-icon"
      [matBadge]="alertCount > 0 ? alertCount : null"
      matBadgeColor="warn"
      [matMenuTriggerFor]="belowMenu"
      (click)="toggleDropdown()"
      >notifications</mat-icon
    >
    <mat-menu #belowMenu="matMenu" yPosition="below">
      <div class="px-3 mb-2">
        <p class="notification-header">Notifications</p>
      </div>

      <div *ngIf="employeeAlerts.length; else noDataTemplate">
        <div
          class="px-3 notification-body"
          *ngFor="let alert of employeeAlerts"
        >
          <p class="mb-0 py-2">
            <span style="font-weight: 600"
              >{{ alert.firstName }} {{ alert.lastName
              }}<!-- ({{alert.employeeCode}}) --> </span
            >'s
            <span
              *ngFor="let sequence of alert.complianceSequence"
              (click)="
                onCompSequenceClick(
                  sequence.compSequenceType,
                  alert.firstName,
                  alert.lastName,
                  alert.employeeId,
                  alert.employeeCode
                )
              "
              class="text-primary notification-document"
              >{{ sequence.compSequenceTypeName }},
            </span>
            <span class="notification-text">has been expired!</span>
          </p>
        </div>
      </div>
      <ng-template #noDataTemplate>
        <!-- <mat-spinner
        style="width: 30px; height: 30px; margin: auto"
      ></mat-spinner> -->
        <div class="no-data-message text-center p-3">
          <p>No New Notifications</p>
        </div>
      </ng-template>
    </mat-menu>
  </div>
</section>
