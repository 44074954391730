<!-- <div style="width: 100% !important; height: 100%">
  <a class="close-icon fs-6 text-danger p-2 float-end" (click)="closeDialog()">
    <mat-icon class="mat-icon">close</mat-icon>
  </a>

  <iframe [src]="safeDocumentUrl" style="width: 100%; height: 93.9%"></iframe>
</div> -->

<div style="width: 100% !important; height: 100%">
  <a class="close-icon fs-6 text-danger p-2 float-end" (click)="closeDialog()">
    <mat-icon class="mat-icon">close</mat-icon>
  </a>

  <iframe
    [src]="safeDocumentUrl"
    style="width: 100%; height: 93.9%; object-fit: contain"
  ></iframe>
</div>
