import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeFormComponent } from 'src/app/dashboard/employee-info/employee-form/employee-form.component';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { TokenService } from 'src/app/shared/services/jwt.service';
import { SubscriptionPaymentService } from 'src/app/shared/services/subscription.service';
import { IdleUserService } from 'src/app/shared/services/idle-user.service';
import { SocService } from 'src/app/shared/services/soc.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  permissions: any[] = [];
  companyName!: string;
  firstName!: string;
  lastName!: string;
  role!: string;
  roleId!: number;
  isSubscriptionExpired!: boolean;
  isUserIdle: boolean = false;
  countdownValue: number = 0;
  docCode!: string;
  base64!: string;
  isLoadingRequired: boolean = false;
  constructor(
    private observer: BreakpointObserver,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private dialog: MatDialog,
    public permissionService: PermissionService,
    private tokenService: TokenService,
    private subscriptionPaymentService: SubscriptionPaymentService,
    private idleUserService: IdleUserService,
    private socService: SocService
  ) {
    this.permissionService.getPermissionAsync().subscribe((permissions) => {
      this.permissions = permissions;
    });

    this.roleId = this.tokenService.getRoleInfoIdFromToken();
  }

  ngOnInit() {
    const token = localStorage.getItem('token');
    if (token) {
      this.isLoadingRequired = true;
      const decodedToken = this.jwtHelper.decodeToken(token);
      this.companyName = decodedToken.CompanyName;
      this.firstName = decodedToken.FirstName;
      this.lastName = decodedToken.LastName;
      this.role = decodedToken.Role;
      this.docCode = decodedToken.DocCode;
      if (this.docCode) {
        this.socService
          .getS3DocumentAsync(this.docCode)
          .subscribe((response) => {
            if (Array.isArray(response) && response.length > 0) {
              const document = response[0];
              if (
                document &&
                document.docBase64String &&
                document.fileExtensionTypeName
              ) {
                const mimeType = this.getMimeType(
                  document.fileExtensionTypeName
                );
                this.base64 = `data:${mimeType};base64,${document.docBase64String}`;
                this.isLoadingRequired = false;
              } else {
                console.error(
                  'Base64 string or file extension type is not available in the document object'
                );
                this.isLoadingRequired = false;
              }
            }
          });
      }
    } else {
      console.error('Token not found in local storage');
      this.isLoadingRequired = false;
    }
    this.subscriptionPaymentService
      .fetchSubscriptionStatus()
      .subscribe((status) => {
        this.isSubscriptionExpired = status;
      });

    this.idleUserService.userInactive.subscribe(
      (isIdle) => (this.isUserIdle = isIdle)
    );
    this.idleUserService.countdown$.subscribe(
      (countdown) => (this.countdownValue = countdown)
    );
  }

  reset() {
    console.log('Reset idle timer');
    this.isUserIdle = false;
    this.idleUserService.reset();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.observer.observe(['(max-width: 768px)']).subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });
    });
  }

  redirectToLogin() {
    const token = localStorage.getItem('token');
    if (token) {
      const loginUrl = `http://dev.timeseel.com/auth/login?id=${token}`;
      window.location.href = loginUrl;
    } else {
      console.error('Token not found in local storage.');
    }
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('selectedEmployeeId');
    localStorage.removeItem('selectedEmployeeFirstName');
    localStorage.removeItem('selectedEmployeeLastName');
    localStorage.removeItem('selectedEmployeeCode');
    this.router.navigate(['/auth']);
  }

  onSelectEmployee() {
    this.dialog.open(EmployeeFormComponent, {
      width: '1200px',
      height: '600px',
    });
  }

  isTaskDetailsRoute(): boolean {
    return this.router.url.endsWith('/content/task-management/task-details');
  }
  private getMimeType(extension: string): string {
    const mimeTypes: { [key: string]: string } = {
      PNG: 'image/png',
      JPG: 'image/jpeg',
      JPEG: 'image/jpeg',
      GIF: 'image/gif',
      SVG: 'image/svg+xml',
    };

    return mimeTypes[extension.toUpperCase()] || 'application/octet-stream';
  }
}
