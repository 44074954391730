import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DashboardPopupData } from '../model/chart.model';
import { SocService } from 'src/app/shared/services/soc.service';
import { DocumentPreviewComponent } from 'src/app/shared/document-preview/document-preview.component';
import { FileHandlerComponent } from 'src/app/shared/components/file-handler/file-handler.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-popup',
  templateUrl: './company-popup.component.html',
  styleUrls: ['./company-popup.component.scss'],
})
export class CompanyPopupComponent {
  isLoadingRequired: boolean = false;
  fileHandler: FileHandlerComponent;

  constructor(
    public dialogRef: MatDialogRef<CompanyPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DashboardPopupData,
    private socService: SocService,
    public dialog: MatDialog,
    private router:Router
  ) {
    this.fileHandler = new FileHandlerComponent();
  }

  goToCompanyDocuments() {
    sessionStorage.setItem('selectedTabIndex',"1");
    this.router.navigate(['/content/my-company']);
    this.dialogRef.close();
  }
  isDataAvailable(): boolean {
    return (
      this.data && this.data.documentList && this.data.documentList.length > 0
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getIconUrl(fileExtensionType: string): string {
    return this.fileHandler.getIconUrl(fileExtensionType);
  }

  downloadFile(doc: any): void {
    if (doc && doc.docCode) {
      this.isLoadingRequired = true;
      // Call the API to get the document based on docCode
      this.socService.getS3DocumentAsync(doc.docCode).subscribe(
        (response) => {
          // Log the response
          console.log('Document response:', response);
          if (response && response[0] && response[0].docBase64String) {
            // Convert the fetched base64 string to a file
            const byteCharacters = atob(response[0].docBase64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            // Ensure that response.fileExtensionTypeName is defined
            if (response[0].fileExtensionTypeName) {
              const fileExtensionType =
                this.fileHandler.getFileExtensionTypeByName(
                  response[0].fileExtensionTypeName
                );
              // Log the file content along with its extension
              console.log('File Content:', byteArray);
              console.log('File Extension:', fileExtensionType);
              const blob = new Blob([byteArray], {
                type: this.fileHandler.getMimeTypeByExtensionType(
                  fileExtensionType
                ),
              });
              // Create a URL for the Blob object
              const fileURL = URL.createObjectURL(blob);

              // Open the file in MatDialog - New Preview Code
              const dialogRef = this.dialog.open(DocumentPreviewComponent, {
                width: '90%', // Adjust width as needed
                height: '90%', // Adjust width as needed
                data: fileURL,
              });

              // Close preview for non image and non pdf
              const unsupportedExtensions = [
                'None',
                'DOC',
                'DOCX',
                'TXT',
                'XLS',
                'XLSX',
                'CSV',
                'PPT',
              ];

              if (
                unsupportedExtensions.includes(
                  response[0].fileExtensionTypeName
                )
              ) {
                console.log(
                  `FileExtension is ${response[0].fileExtensionTypeName}. Hence closing`
                );
                dialogRef.close();
              }

              // Clean up resources
              dialogRef.afterClosed().subscribe(() => {
                URL.revokeObjectURL(fileURL);
              });
              this.isLoadingRequired = false;
            } else {
              console.error(
                'File extension type name not found in the document response'
              );
              this.isLoadingRequired = false;
            }
          } else {
            console.error('Base64 string not found in the document response');
            this.isLoadingRequired = false;
          }
        },
        (error) => {
          console.error('Error fetching document:', error);
          this.isLoadingRequired = false;
        }
      );
    } else {
      console.error('Document code not found');
      this.isLoadingRequired = false;
    }
  }
}
