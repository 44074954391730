import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/shared/services/jwt.service';
import { DashboardChartService } from '../service/dashboard-chart.service';

@Component({
  selector: 'app-company-chart',
  templateUrl: './company-chart.component.html',
  styleUrls: ['./company-chart.component.scss'],
})
export class CompanyChartComponent implements OnInit {
  chartOptions: any;
  companyInfoId!: number;
  totalCompletedPercentage: number = 0;
  totalIncompletePercentage: number = 0;

  constructor(
    private dashboardChartService: DashboardChartService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.getEmployeeDocumentData();
  }

  getEmployeeDocumentData(): void {
    this.companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    this.dashboardChartService
      .getEmployeeDocumentStatus(this.companyInfoId)
      .subscribe(
        (data) => {
          console.log('API Response:', data); // Log the entire API response
          if (
            data &&
            data.length > 0 &&
            data[0].employeeDetails &&
            data[0].employeeDetails.length > 0
          ) {
            const totalCompletedDocuments = data[0].employeeDetails.reduce(
              (total, emp) => total + emp.completedDocument,
              0
            );
            const totalIncompleteDocuments = data[0].employeeDetails.reduce(
              (total, emp) => total + emp.incompleteDocument,
              0
            );
            const totalDocuments = data[0].employeeDetails.reduce(
              (total, emp) => total + emp.totalDocument,
              0
            );
            this.totalCompletedPercentage = parseFloat(
              ((totalCompletedDocuments / totalDocuments) * 100).toFixed(2)
            );
            this.totalIncompletePercentage = parseFloat(
              ((totalIncompleteDocuments / totalDocuments) * 100).toFixed(2)
            );
            this.chartOptions = {
              series: [
                this.totalCompletedPercentage,
                this.totalIncompletePercentage,
              ],
              chart: {
                width: 380,
                type: 'donut',
              },
              style: {
                margin: 'auto',
              },
              title: {
                text: "Company's Compliance",
                align: 'center',
                css: {
                  fontFamily: '"Poppins", sans-serif',
                },
              },
              // labels: [
              //  'Compliance : ' + this.totalCompletedPercentage + '%',
              //   'Non-Compliance : ' + this.totalIncompletePercentage + '%',
              // ],
              labels: ['Compliance', 'Non-Compliance'],
              tooltip: {
                y: {
                  formatter: (value: number, { seriesIndex }: any) => {
                    // Map the series index to the corresponding label
                    const label = seriesIndex === 0;
                    return `${value}%`;
                  },
                },
              },

              colors: ['#48b461', '#ff4c5d'],
              legend: {
                position: 'bottom',
              },
              responsive: [
                {
                  breakpoint: 400,
                  options: {
                    chart: {
                      width: 250,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 576,
                  options: {
                    chart: {
                      width: 400,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                // {
                //   breakpoint: 769,
                //   options: {
                //     chart: {
                //       width: 500,
                //     },
                //     legend: {
                //       position: 'bottom',
                //     },
                //   },
                // },
                // {
                //   breakpoint: 992,
                //   options: {
                //     chart: {
                //       width: 400,
                //     },
                //     legend: {
                //       position: 'bottom',
                //     },
                //   },
                // },
                // {
                //   breakpoint: 1000,
                //   options: {
                //     chart: {
                //       width: 200,
                //     },
                //     legend: {
                //       position: 'bottom',
                //     },
                //   },
                // },
                // {
                //   breakpoint: 1100,
                //   options: {
                //     chart: {
                //       width: 225,
                //     },
                //     legend: {
                //       position: 'bottom',
                //     },
                //   },
                // },
                {
                  breakpoint: 1200,
                  options: {
                    chart: {
                      width: 500,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 1300,
                  options: {
                    chart: {
                      width: 275,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 1400,
                  options: {
                    chart: {
                      width: 300,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 1500,
                  options: {
                    chart: {
                      width: 340,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 1600,
                  options: {
                    chart: {
                      width: 360,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 1700,
                  options: {
                    chart: {
                      width: 400,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
              ],
            };
          } else {
            // If no data is found, display an empty donut chart with a "No Data" label
            this.chartOptions = {
              series: [100],
              chart: {
                width: 380,
                type: 'donut',
              },
              title: {
                text: "Company's Compliance",
                align: 'center',
                css: {
                  fontFamily: '"Poppins", sans-serif', // Set the desired font family here
                },
              },
              labels: ['No Data'],
              colors: ['grey'], // Grey color
              legend: {
                show: false,
              },
              responsive: [
                {
                  breakpoint: 400,
                  options: {
                    chart: {
                      width: 250,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 576,
                  options: {
                    chart: {
                      width: 400,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                // {
                //   breakpoint: 769,
                //   options: {
                //     chart: {
                //       width: 500,
                //     },
                //     legend: {
                //       position: 'bottom',
                //     },
                //   },
                // },
                // {
                //   breakpoint: 992,
                //   options: {
                //     chart: {
                //       width: 400,
                //     },
                //     legend: {
                //       position: 'bottom',
                //     },
                //   },
                // },
                // {
                //   breakpoint: 1000,
                //   options: {
                //     chart: {
                //       width: 200,
                //     },
                //     legend: {
                //       position: 'bottom',
                //     },
                //   },
                // },
                // {
                //   breakpoint: 1100,
                //   options: {
                //     chart: {
                //       width: 225,
                //     },
                //     legend: {
                //       position: 'bottom',
                //     },
                //   },
                // },
                {
                  breakpoint: 1200,
                  options: {
                    chart: {
                      width: 500,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 1300,
                  options: {
                    chart: {
                      width: 275,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 1400,
                  options: {
                    chart: {
                      width: 300,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 1500,
                  options: {
                    chart: {
                      width: 340,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 1600,
                  options: {
                    chart: {
                      width: 360,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
                {
                  breakpoint: 1700,
                  options: {
                    chart: {
                      width: 400,
                    },
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
              ],
            };
            console.error('No employee details found in API response.');
          }
        },
        (error) => {
          console.error('Error fetching data:', error);
        }
      );
  }
}
