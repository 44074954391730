<div class="card shadow-sm border-0" style="width: 500px;">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex">
          <mat-icon class="text-danger me-2">warning</mat-icon>
          <h3 class="card-title mb-0 fw-bolder fs-5">Delete Confirmation</h3>
        </div>
        
        <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
      </div>
      <div class="alert alert-danger text-start mt-4">
        <span [innerHTML]="data.message"></span>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <button type="button" class="btn me-2 btn-outline-primary btn-sm" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm" (click)="confirmDelete()">Delete</button>
      </div>
    </div>
</div>
  