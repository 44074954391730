import { Component } from '@angular/core';
import { DashboardChartService } from '../service/dashboard-chart.service';
import { TokenService } from 'src/app/shared/services/jwt.service';
import { ApiResponse } from '../model/chart.model';

@Component({
  selector: 'app-employee-donut',
  templateUrl: './employee-donut.component.html',
  styleUrls: ['./employee-donut.component.scss'],
})
export class EmployeeDonutComponent {
  chartOptions: any;
  companyInfoId: number;

  constructor(
    private dashboardChartService: DashboardChartService,
    private tokenService: TokenService
  ) {
    this.companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
  }
  
  ngOnInit() {
    this.getEmployeeChart();
    this.updateEmployeeComp();
  }

  getEmployeeChart(): void {
    this.dashboardChartService
      .getEmployeeDocumentStatus(this.companyInfoId)
      .subscribe(
        (responses: ApiResponse[]) => {
          if (responses && responses.length > 0) {
            const response = responses[0];
            const employeeCount = response.employeeCount;
  
            // Calculate total values for only two metrics
            const totalValues = employeeCount.incompleteProfiles + employeeCount.completedProfiles;
  
            // Check if any non-zero value exists
            if (totalValues > 0) {
              const incompleteProfilesPercentage = parseFloat(
                ((employeeCount.incompleteProfiles / totalValues) * 100).toFixed(2)
              );
              const completedProfilesPercentage = parseFloat(
                ((employeeCount.completedProfiles / totalValues) * 100).toFixed(2)
              );
  
              this.chartOptions = {
                series: [
                  incompleteProfilesPercentage,
                  completedProfilesPercentage,
                ],
                chart: {
                  width: 380,
                  type: 'donut',
                },
                labels: [
                  'Incomplete',
                  'Completed',
                ],
                // labels: [
                //   'Incomplete : ' + incompleteProfilesPercentage.toFixed(2) + '%',
                //   'Completed : ' + completedProfilesPercentage.toFixed(2) + '%',
                // ],
                tooltip: {
                  y: {
                    formatter: (value: number, { seriesIndex }: any) => {
                      const label = seriesIndex === 0;
                      return `${value}%`;
                    },
                  },
                },
                
                colors: ['#DC3545', '#198754'], // Adjust colors for two metrics
                legend: {
                  position: 'bottom',
                },
                responsive: [
                  {
                    breakpoint: 576,
                    options: {
                      chart: {
                        width: 350,
                        margin: 'auto',
                      },
                      legend: {
                        position: 'bottom',
                      },
                    },
                  },
                  {
                    breakpoint: 769,
                    options: {
                      chart: {
                        width: 400,
                      },
                      legend: {
                        position: 'bottom',
                      },
                    },
                  },
                  {
                    breakpoint: 992,
                    options: {
                      chart: {
                        width: 400,
                      },
                      legend: {
                        position: 'bottom',
                      },
                    },
                  },
                  {
                    breakpoint: 1200,
                    options: {
                      chart: {
                        width: 500,
                      },
                      legend: {
                        position: 'bottom',
                      },
                    },
                  },
                  {
                    breakpoint: 1400,
                    options: {
                      chart: {
                        width: 450,
                      },
                      legend: {
                        position: 'bottom',
                      },
                    },
                  },
                ],
              };
            } else {
              this.chartOptions = {
                series: [100],
                chart: {
                  width: 380,
                  type: 'donut',
                },
                labels: ['No data available'],
                colors: ['#CCCCCC'],
                legend: {
                  show: false,
                },
              };
            }
          } else {
            console.error('Empty response or no ApiResponse objects found.');
          }
        },
        (error) => {
          console.error('Error fetching employee document status:', error);
        }
      );
  }
  
  updateEmployeeComp(): void {
    this.dashboardChartService.updatedEmployeeComp.subscribe(() => {
      this.getEmployeeChart();
    });
  }
}
