import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Alert,
  ChatAlert,
  EmployeeAlert,
  UpdateAlertRequest,
} from '../models/notification.model';
import { TokenService } from './jwt.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private masterAlertApiUrlBase = `${environment.apiUrl}/api/1.0/getMasterExpiredAlertAsync`;
  private employeeAlertApiUrlBase = `${environment.apiUrl}/api/1.0/getEmployeeExpiredDocumentAlertAsync`;
  private updateAlertApiUrl = `${environment.apiUrl}/api/1.0/updateMasterExpiredAlertAsync`;
  private chatAlertApiUrlBase = `${environment.apiUrl}/api/1.0/getTaskAndChatAlertListAsync`;
  
  noticationCount: EventEmitter<void> = new EventEmitter<void>();

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getMasterAlerts(): Observable<Alert[]> {
    const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    const userInfoId = this.tokenService.getUserInfoIdFromToken();
    const roleInfoId = this.tokenService.getRoleInfoIdFromToken();
    const url = `${this.masterAlertApiUrlBase}?CompanyInfoId=${companyInfoId}&UserInfoId=${userInfoId}&RoleInfoId=${roleInfoId}`;
    return this.http.get<Alert[]>(url);
  }

  getChatAlerts(): Observable<ChatAlert[]> {
    const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    const userInfoId = this.tokenService.getUserInfoIdFromToken();
    const roleInfoId = this.tokenService.getRoleInfoIdFromToken();

    const apiUrl = `${this.chatAlertApiUrlBase}?CompanyInfoId=${companyInfoId}&UserInfoId=${userInfoId}&RoleInfoId=${roleInfoId}`;
    
    return this.http.get<ChatAlert[]>(apiUrl);
  }
  getEmployeeAlerts(): Observable<EmployeeAlert[]> {
    const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    const url = `${this.employeeAlertApiUrlBase}?CompanyInfoId=${companyInfoId}`;
    return this.http.get<EmployeeAlert[]>(url);
  }

  updateMasterAlert(request: UpdateAlertRequest): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'text/plain',
    });
    return this.http.post<any>(this.updateAlertApiUrl, request, { headers });
  }
}
