import { Component, Inject, ViewChild } from '@angular/core';
import { EmployeeChart } from '../../dashboard/model/chart.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { DocumentPreviewComponent } from 'src/app/shared/document-preview/document-preview.component';
import { SocService } from 'src/app/shared/services/soc.service';
import { Router } from '@angular/router';
import { FileHandlerComponent } from 'src/app/shared/components/file-handler/file-handler.component';
import * as XLSX from 'xlsx';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss'],
})
export class EmployeeListComponent {
  isLoadingRequired: boolean = false;
  fileHandler: FileHandlerComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  
  filteredEmployeeChart: EmployeeChart[] = [];
  dataSource = new MatTableDataSource<EmployeeChart>([]);

  pageSize: number = 10;
  currentPageIndex: number = 0;
  searchText: string = '';
  totalItems: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmployeeChart[],
    public dialog: MatDialog,
    private socService: SocService,
    public dialogRef: MatDialogRef<EmployeeListComponent>,
    private router: Router
  ) {
    this.fileHandler = new FileHandlerComponent();
    this.filteredEmployeeChart = this.data.slice(0, this.pageSize);
    this.totalItems = this.data.length;
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  isDataAvailable(): boolean {
    return this.data && this.data.length > 0;
  }

  onViewEmployee(employee: EmployeeChart): void {
    localStorage.setItem('selectedEmployeeId', employee.employeeId.toString());
    localStorage.setItem(
      'selectedCompSequenceTypeId',
      employee.compSequenceType.toString()
    );
    localStorage.setItem('selectedEmployeeFirstName', employee.firstName);
    localStorage.setItem('selectedEmployeeLastName', employee.lastName);
    localStorage.setItem('selectedEmployeeCode', employee.employeeCode);
    // Route based on compSequenceTypeId
    switch (employee.compSequenceType) {
      case 6:
        this.router.navigate([
          'content/employee-info/employee-details/passport',
        ]);
        break;
      case 7:
        this.router.navigate([
          'content/employee-info/employee-details/biometric',
        ]);
        break;
      case 8:
        this.router.navigate([
          'content/employee-info/employee-details/right-to-work',
        ]);
        break;
      case 9:
        this.router.navigate([
          'content/employee-info/employee-details/cos-detail',
        ]);
        break;
      default:
        console.error('Invalid compSequenceType');
        break;
    }
    this.dialog.closeAll();
  }

  getIconUrl(fileExtensionType: string): string {
    return this.fileHandler.getIconUrl(fileExtensionType);
  }

  downloadFile(employee: any): void {
    if (employee && employee.resumeDocCode) {
      this.isLoadingRequired = true;
      // Call the API to get the document based on docCode
      this.socService.getS3DocumentAsync(employee.resumeDocCode).subscribe(
        (response) => {
          // Log the response
          console.log('Document response:', response);
          if (response && response[0] && response[0].docBase64String) {
            // Convert the fetched base64 string to a file
            const byteCharacters = atob(response[0].docBase64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            // Ensure that response.fileExtensionTypeName is defined
            if (response[0].fileExtensionTypeName) {
              const fileExtensionType =
                this.fileHandler.getFileExtensionTypeByName(
                  response[0].fileExtensionTypeName
                );
              // Log the file content along with its extension
              console.log('File Content:', byteArray);
              console.log('File Extension:', fileExtensionType);
              const blob = new Blob([byteArray], {
                type: this.fileHandler.getMimeTypeByExtensionType(
                  fileExtensionType
                ),
              });
              // Create a URL for the Blob object
              const fileURL = URL.createObjectURL(blob);

              // Open the file in MatDialog - New Preview Code
              const dialogRef = this.dialog.open(DocumentPreviewComponent, {
                width: '90%', // Adjust width as needed
                height: '90%', // Adjust width as needed
                data: fileURL,
              });

              // Close preview for non image and non pdf
              const unsupportedExtensions = [
                'None',
                'DOC',
                'DOCX',
                'TXT',
                'XLS',
                'XLSX',
                'CSV',
                'PPT',
              ];

              if (
                unsupportedExtensions.includes(
                  response[0].fileExtensionTypeName
                )
              ) {
                console.log(
                  `FileExtension is ${response[0].fileExtensionTypeName}. Hence closing`
                );
                dialogRef.close();
              }

              // Clean up resources
              dialogRef.afterClosed().subscribe(() => {
                URL.revokeObjectURL(fileURL);
              });
              this.isLoadingRequired = false;
            } else {
              console.error(
                'File extension type name not found in the document response'
              );
              this.isLoadingRequired = false;
            }
          } else {
            console.error('Base64 string not found in the document response');
            this.isLoadingRequired = false;
          }
        },
        (error) => {
          console.error('Error fetching document:', error);
          this.isLoadingRequired = false;
        }
      );
    } else {
      console.error('Document code not found');
      this.isLoadingRequired = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close('no');
  }

  getFullName(employee: EmployeeChart): string {
    return `${employee.firstName} ${employee.lastName}`;
  }

  downloadExcel(): void {
    // Convert Passport to Excel format
    const dataForExcel = this.data.map((employee) => ({
      // 'Employee Id': hiringData.employeeId,
      'Employee Name': this.getFullName(employee),
      'Mobile No': employee.mobileNo,
      'Email Id': employee.emailId,
      'Document': employee.compSequenceTypeName,
      'Expiry Date': this.formatDate(employee.expiryDate),
      'Resume': employee.resumeFileExtensionTypeName,
    }));

    // Create a new Excel workbook
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'EmployeeChart');

    // Save the Excel file
    XLSX.writeFile(workbook, 'Employee_Chart.xlsx');
  }

  formatDate(date: string): string {
    const d = new Date(date);
    return d.toISOString().split('T')[0]; // Extract only the date part in 'YYYY-MM-DD' format
  }

  formatDateTime(date: Date): string {
    const d = new Date(date);
    const day = ('0' + d.getDate()).slice(-2); // Add leading zero if needed
    const month = ('0' + (d.getMonth() + 1)).slice(-2); // Add leading zero if needed
    const year = d.getFullYear();
    const hours = ('0' + d.getHours()).slice(-2); // Add leading zero if needed
    const minutes = ('0' + d.getMinutes()).slice(-2); // Add leading zero if needed
    return `${day}-${month}-${year} & ${hours}:${minutes}`; // Format: dd-MM-yyyy hh:mm
  }

  filterEmployeeChart() {
    if (!this.searchText) {
      // Reset to the full list and paginate
      this.filteredEmployeeChart = this.data.slice(0, this.pageSize);
      this.dataSource.data = this.filteredEmployeeChart;
      this.paginator.length = this.data.length;
      this.currentPageIndex = 0; // Reset to first page
      this.paginator.firstPage();
      return;
    }

    const searchTerm = this.searchText.toLowerCase();
    const data = this.data.filter((employee) => {
      const fullName =
        `${employee.firstName} ${employee.lastName}`.toLowerCase();
      if (fullName.includes(searchTerm)) {
        return true;
      }

      return Object.values(employee).some((value) => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchTerm);
        } else if (typeof value === 'number') {
          return value.toString().toLowerCase().includes(searchTerm);
        } else if (value instanceof Date) {
          const formattedDate = value.toLocaleDateString().toLowerCase();
          return formattedDate.includes(searchTerm);
        }
        return false;
      });
    });

    // Set filtered list and pagination
    this.filteredEmployeeChart = data.slice(0, this.pageSize);
    this.dataSource.data = this.filteredEmployeeChart;
    this.paginator.length = data.length;
    this.currentPageIndex = 0; // Reset to first page
    this.paginator.firstPage(); // Navigate to the first page
  }

  onPageChange(event?: PageEvent): void {
    if (event) {
      this.currentPageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    }
    const startIndex = this.currentPageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.filteredEmployeeChart = this.data.slice(
      startIndex,
      endIndex
    );
    this.dataSource.data = this.filteredEmployeeChart;
  }

  sortData(sort: Sort): void {
    // Copy the entire dataset
    const data = this.data.slice(); // Sort the entire list
    if (!sort.active || sort.direction === '') {
      this.filteredEmployeeChart = data.slice(0, this.pageSize); // Reset to unsorted list
      this.dataSource.data = this.filteredEmployeeChart;
      this.paginator.firstPage(); // Navigate to the first page
      return;
    }

    // Sort the data
    this.data = data.sort((a, b) => {
      // Sort the whole list
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fullName':
          return this.compare(
            a.firstName + a.lastName,
            b.firstName + b.lastName,
            isAsc
          );
        case 'mobileNo':
          return this.compare(a.mobileNo, b.mobileNo, isAsc);
        case 'emailId':
          return this.compare(a.emailId, b.emailId, isAsc);
        case 'compSequenceTypeName':
          return this.compare(a.compSequenceTypeName, b.compSequenceTypeName, isAsc);
        case 'expiryDate':
          return this.compare(
            a.expiryDate,
            b.expiryDate,
            isAsc
          );
        default:
          return 0;
      }
    });

    this.onPageChange(); // Update the view based on the current page
  }

  compare(a: any, b: any, isAsc: boolean): number {
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    });
    const result = collator.compare(a, b);
    return result * (isAsc ? 1 : -1);
  }
}
