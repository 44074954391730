import { Component, OnInit, TemplateRef } from '@angular/core';
import { DashboardChartService } from '../service/dashboard-chart.service';
import {
  CardValue,
  CompanyDocCardType,
  DashboardPopupData,
} from '../model/chart.model';
import { CompanyPopupComponent } from '../company-popup/company-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { TokenService } from 'src/app/shared/services/jwt.service';
import { NgIfContext } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-count',
  templateUrl: './company-count.component.html',
  styleUrls: ['./company-count.component.scss'],
})
export class CompanyCountComponent implements OnInit {
  companyDocCardType: CompanyDocCardType | null = null;
  noTooltip!: TemplateRef<NgIfContext<boolean>> | null;

  labelBackgrounds: { [key: number]: string } = {
    1: 'bg-success',
    2: 'bg-info',
    3: 'bg-secondary',
    4: 'bg-warning',
    5: 'bg-danger',
  };

  constructor(
    private dashboardChartService: DashboardChartService,
    private dialog: MatDialog,
    private tokenService: TokenService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCompanyDocCardData();
  }

  getCompanyDocCardData(): void {
    this.dashboardChartService.getCompanyDocCardType().subscribe((data) => {
      this.companyDocCardType = data[0];
    });
  }

  getCardTypes(): CardValue[] {
    if (!this.companyDocCardType) {
      return [];
    }
    return [
      this.companyDocCardType.cardExpiredValue,
      this.companyDocCardType.card90Value,
      this.companyDocCardType.card60Value,
      this.companyDocCardType.card30Value,
      this.companyDocCardType.card7Value,
    ];
  }

  handleCardClick(cardType: CardValue): void {
    if (cardType.totalCount !== 0) {
      const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
      this.dashboardChartService
        .getCompanyDocumentList(
          companyInfoId,
          cardType.expirdType,
          cardType.listIds
        )
        .subscribe((dataArray) => {
          const data = dataArray[0];
          console.log(data);
          this.openDashboardPopup(data);
        });
    } else {
      this.handleNoData();
    }
  }

  navigateTo(): void {
    sessionStorage.setItem('selectedTabIndex', '1');
    this.router.navigate(['/content/my-company']);
  }
  openDashboardPopup(data: DashboardPopupData): void {
    this.dialog.open(CompanyPopupComponent, {
      width: '90%', // Adjust width as needed
      height: '90%', // Adjust width as needed
      data: data,
    });
  }

  handleNoData(): void {
    // Implement your no data logic here
  }

  getLabelBackground(expirdType: number): string {
    return this.labelBackgrounds[expirdType] || '';
  }

  getCardClass(cardTypeName: string): string {
    switch (cardTypeName) {
      case 'Expired':
        return 'card-expiry-type-passport';
      case '90 Days':
        return 'card-expiry-type-brp';
      case '60 Days':
        return 'card-expiry-type-rtw';
      case '30 Days':
        return 'card-expiry-type-cos';
      case '7 Days':
        return 'card-expiry-type-passport'; // Adjust as needed
      default:
        return '';
    }
  }
}
