import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; // Import Router module
import { SubscriptionPaymentService } from 'src/app/shared/services/subscription.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  isSubscribed: boolean = true;
  isLoadingRequired: boolean = false;

  constructor(
    private subscriptionService: SubscriptionPaymentService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoadingRequired = true;
    this.subscriptionService.fetchSubscriptionStatus().subscribe(
      (status: boolean) => {
        this.isSubscribed = status;
        this.isLoadingRequired = false;
      },
      (error) => {
        console.error('Error fetching subscription status', error);
        this.isLoadingRequired = false;
      }
    );
  }
}
