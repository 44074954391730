<div *ngIf="passportValue">
  <div class="row">
    <div class="col-md-6 mb-2 px-2">
      <div class="card card-expiry-type-passport">
        <div class="card-body" style="padding: 12px 6px">
          <div class="d-flex align-items-center justify-content-around">
            <div class="text-center" style="width: 106px">
              <div>
                <h3 class="fw-bold text-left">
                  {{ passportValue.cardTypeName }}
                </h3>
              </div>
              <div class="rounded-circle divIconPassport">
                <mat-icon>assignment_ind</mat-icon>
              </div>
            </div>
            <div class="divDocumentPassport">
              <p
                *ngFor="let expiredType of passportValue.expiredType"
                class="mb-0"
                (click)="
                  expiredType.value !== 0
                    ? handleCardAndExpiredTypeClick({
                        cardType: passportValue.cardType,
                        expiredType: expiredType
                      })
                    : handleNoData()
                "
                [style.cursor]="expiredType.value === 0 ? 'no-drop' : 'pointer'"
              >
                <span class="btnDocumentCategory">{{
                  expiredType.expirdTypeName
                }}</span>
                <span> : </span>
                <span
                  *ngIf="expiredType.value !== 0; else noTooltip"
                  matTooltip="Employee Data"
                  class="expiry-value"
                  [ngClass]="getLabelBackground(expiredType.expirdType)"
                  >{{ expiredType.value }}</span
                >
                <ng-template #noTooltip>
                  <span
                    class="expiry-value"
                    [ngClass]="getLabelBackground(expiredType.expirdType)"
                    >{{ expiredType.value }}</span
                  >
                </ng-template>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 mb-2 px-2">
      <div class="card card-expiry-type-brp">
        <div class="card-body" style="padding: 12px 6px">
          <div class="d-flex align-items-center justify-content-around">
            <div style="width: 106px">
              <div>
                <h3 class="fw-bold ml-3">{{ brpValue.cardTypeName }}</h3>
              </div>
              <div class="rounded-circle divIconBRP">
                <mat-icon>badge</mat-icon>
              </div>
            </div>
            <div class="divDocumentBRP">
              <p
                *ngFor="let expiredType of brpValue.expiredType"
                class="mb-0"
                (click)="
                  expiredType.value !== 0
                    ? handleCardAndExpiredTypeClick({
                        cardType: brpValue.cardType,
                        expiredType: expiredType
                      })
                    : handleNoData()
                "
                [style.cursor]="expiredType.value === 0 ? 'no-drop' : 'pointer'"
              >
                <span class="btnDocumentCategory">{{
                  expiredType.expirdTypeName
                }}</span>
                <span> : </span>
                <span
                  *ngIf="expiredType.value !== 0; else noTooltip"
                  matTooltip="Employee Data"
                  class="expiry-value"
                  [ngClass]="getLabelBackground(expiredType.expirdType)"
                  >{{ expiredType.value }}</span
                >
                <ng-template #noTooltip>
                  <span
                    class="expiry-value"
                    [ngClass]="getLabelBackground(expiredType.expirdType)"
                    >{{ expiredType.value }}</span
                  >
                </ng-template>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 px-2">
      <div class="card card-expiry-type-rtw">
        <div class="card-body" style="padding: 12px 6px">
          <div class="d-flex align-items-center justify-content-around">
            <div class="text-center" style="width: 106px">
              <div>
                <h3 class="fw-bold text-left" style="margin-left: -16px">
                  {{ rightToWorkValue.cardTypeName }}
                </h3>
              </div>
              <div class="rounded-circle divIconRTW">
                <mat-icon>chrome_reader_mode</mat-icon>
              </div>
            </div>
            <div class="divDocumentRTW">
              <p
                *ngFor="let expiredType of rightToWorkValue.expiredType"
                class="mb-0"
                (click)="
                  expiredType.value !== 0
                    ? handleCardAndExpiredTypeClick({
                        cardType: rightToWorkValue.cardType,
                        expiredType: expiredType
                      })
                    : handleNoData()
                "
                [style.cursor]="expiredType.value === 0 ? 'no-drop' : 'pointer'"
              >
                <span class="btnDocumentCategory">{{
                  expiredType.expirdTypeName
                }}</span>
                <span> : </span>
                <span
                  *ngIf="expiredType.value !== 0; else noTooltip"
                  matTooltip="Employee Data"
                  class="expiry-value"
                  [ngClass]="getLabelBackground(expiredType.expirdType)"
                  >{{ expiredType.value }}</span
                >
                <ng-template #noTooltip>
                  <span
                    class="expiry-value"
                    [ngClass]="getLabelBackground(expiredType.expirdType)"
                    >{{ expiredType.value }}</span
                  >
                </ng-template>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 px-2">
      <div class="card card-expiry-type-cos">
        <div class="card-body" style="padding: 12px 6px">
          <div class="d-flex align-items-center justify-content-around">
            <div style="width: 106px">
              <div>
                <h3 class="fw-bold ml-3">{{ cosValue.cardTypeName }}</h3>
              </div>
              <div class="rounded-circle divIconCOS">
                <mat-icon>class</mat-icon>
              </div>
            </div>
            <div class="divDocumentCOS">
              <p
                *ngFor="let expiredType of cosValue.expiredType"
                class="mb-0"
                (click)="
                  expiredType.value !== 0
                    ? handleCardAndExpiredTypeClick({
                        cardType: cosValue.cardType,
                        expiredType: expiredType
                      })
                    : handleNoData()
                "
                [style.cursor]="expiredType.value === 0 ? 'no-drop' : 'pointer'"
              >
                <span class="btnDocumentCategory">{{
                  expiredType.expirdTypeName
                }}</span>
                <span> : </span>
                <span
                  *ngIf="expiredType.value !== 0; else noTooltip"
                  matTooltip="Employee Data"
                  class="expiry-value"
                  [ngClass]="getLabelBackground(expiredType.expirdType)"
                  >{{ expiredType.value }}</span
                >
                <ng-template #noTooltip>
                  <span
                    class="expiry-value"
                    [ngClass]="getLabelBackground(expiredType.expirdType)"
                    >{{ expiredType.value }}</span
                  >
                </ng-template>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
