<div *ngIf="isLoadingRequired">
  <app-loading-animation></app-loading-animation>
</div>
<section style="width: 550px">
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center me-2">
        <svg height="25" width="10" style="margin-right: 5px">
          <line
            x1="5"
            y1="0"
            x2="5"
            y2="50"
            style="stroke: rgb(0, 0, 0); stroke-width: 4"
          />
        </svg>
        <p class="my-1 fs-6 fw-bolder">Document verification</p>
      </div>
      <mat-icon style="cursor: pointer" (click)="onNoClick()">close</mat-icon>
    </div>
    <div class="card-body">
      <form [formGroup]="actionForm" (ngSubmit)="onSubmit()">
        <div class="row mb-2">
          <label
            class="form-label p-0 float-start fw-bold"
            for="actionTypeSelect"
          >
            Select Action
          </label>
          <select
            class="form-select"
            formControlName="selectedActionType"
            id="actionTypeSelect"
          >
            <option value="">Select </option>
            <option
              *ngFor="let actionType of actionTypes"
              [value]="actionType.key"
              [ngClass]="{
                'disabled-option': actionType.buttonStateType === 0
              }"
              [attr.disabled]="actionType.buttonStateType === 0 ? true : null"
            >
              {{ actionType.value }}
            </option>
          </select>
        </div>

        <div class="row">
          <label class="form-label p-0 fw-bold" for="remarks">Remarks</label>
          <textarea
            class="form-control"
            type="text"
            formControlName="remarks"
            id="remarks"
            appCapitializeInput
            appLimitedSpecialCharactersInput
          ></textarea>
        </div>
        <div class="text-center">
          <button class="mt-3 btn btn-sm btn-primary" type="submit">
            <mat-icon class="verify-icon">check_circle_outline</mat-icon>
            Verify
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
