<div *ngIf="isLoadingRequired">
  <app-loading-animation></app-loading-animation>
</div>

<div class="card border-0">
  <div class="card-header d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <svg height="25" width="10" style="margin-right: 5px">
        <line
          x1="5"
          y1="0"
          x2="5"
          y2="50"
          style="stroke: rgb(0, 0, 0); stroke-width: 4"
        />
      </svg>
      <h3 class="mb-0 fw-bold">Add Employees</h3>
    </div>
    <button
      mat-icon-button
      class="close-icon fs-6 border-0 text-primary bg-light fw-bold"
      (click)="closeDialog()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="card-body">
    <form [formGroup]="employeeForm" (ngSubmit)="submitForm()">
      <div formArrayName="employees">
        <div
          *ngFor="let employee of employeesArray.controls; let i = index"
          [formGroupName]="i"
          class="row"
        >
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
            <label class="form-label mb-1" for="firstName{{ i }}"
              >First Name <span class="text-danger">*</span></label
            >
            <input
              class="form-control"
              type="text"
              id="firstName{{ i }}"
              formControlName="firstName"
              placeholder="First Name"
              appCapitializeTextSpaceInput
              appTrimInput
            />
            <label
              class="form-label text-danger"
              *ngIf="
                employee.get('firstName')?.invalid &&
                (employee.get('firstName')?.dirty ||
                  employee.get('firstName')?.touched)
              "
            >
              First Name is required.
            </label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
            <label class="form-label mb-1" for="lastName{{ i }}"
              >Last Name <span class="text-danger">*</span></label
            >
            <input
              class="form-control"
              type="text"
              id="lastName{{ i }}"
              formControlName="lastName"
              placeholder="Last Name"
              appCapitializeTextSpaceInput
              appTrimInput
            />
            <label
              class="form-label text-danger"
              *ngIf="
                employee.get('lastName')?.invalid &&
                (employee.get('lastName')?.dirty ||
                  employee.get('lastName')?.touched)
              "
            >
              Last Name is required.
            </label>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
            <div class="d-flex">
              <div style="width: 90%">
                <label class="form-label mb-1" for="mobileNo{{ i }}"
                  >Mobile Number <span class="text-danger">*</span></label
                >
                <input
                  class="form-control"
                  type="text"
                  id="mobileNo{{ i }}"
                  formControlName="mobileNo"
                  placeholder="07800 123456"
                  maxlength="11"
                  appNumericInput
                />
                <div
                  *ngIf="
                    employee.get('mobileNo')?.invalid &&
                    (employee.get('mobileNo')?.dirty ||
                      employee.get('mobileNo')?.touched)
                  "
                  class="text-danger"
                >
                  <label
                    class="form-label"
                    *ngIf="
                      employee
                        .get('mobileNo')
                        ?.errors?.hasOwnProperty('required')
                    "
                    >Mobile Number is required.</label
                  >
                  <label
                    class="form-label"
                    *ngIf="
                      employee
                        .get('mobileNo')
                        ?.errors?.hasOwnProperty('minlength')
                    "
                    >Mobile Number must be at least 11 digits.</label
                  >
                  <label
                    class="form-label"
                    *ngIf="
                      employee
                        .get('mobileNo')
                        ?.errors?.hasOwnProperty('maxlength')
                    "
                    >Mobile Number must be at least 11 digits.</label
                  >
                  <label
                    class="form-label"
                    *ngIf="
                      employee
                        .get('mobileNo')
                        ?.errors?.hasOwnProperty('mobileNoValidator')
                    "
                  >
                    Mobile Number must start with '07'
                  </label>
                </div>
              </div>
              <div style="width: 10%; text-align: center">
                <h1
                  *ngIf="i > 0"
                  type="button"
                  (click)="removeEmployee(i)"
                  class="text-secondary mt-4 mb-0"
                >
                  &times;
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-11">
          <div class="d-flex justify-content-between pl-2">
            <div>
              <mat-slide-toggle formControlName="loginAccessType">
                <label class="form-label permission-label"
                  >Login Access</label
                ></mat-slide-toggle
              >
            </div>
            <div>
              <button
                class="btn btn-sm btn-primary"
                type="button"
                (click)="addEmployee()"
              >
                <mat-icon class="mat-icon mat_icon_white outlined-circle-create"
                  >person_add</mat-icon
                >
                Add More
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>

      <div class="row">
        <div class="col-md-4 mx-auto text-center">
          <button
            class="btn btn-sm btn-primary"
            type="submit"
            [disabled]="hasErrors()"
          >
            <mat-icon class="mat-icon mat_icon_white outlined-circle-create"
              >send</mat-icon
            >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
