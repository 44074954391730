<section>
  <div *ngIf="companyDocCardType" class="card card-expiry-type-company">
    <div
      class="card-header fw-bold text-center"
      style="background-color: #e2e3e5"
    >
      Company Documents Expiry

      <mat-icon
        matTooltip="Redirect"
        class="text-primary"
        style="
          font-size: 26px;
          width: 26px;
          height: 26px;
          vertical-align: middle;
          margin-top: -5px;
          position: absolute;
          right: 14px;
          cursor: pointer;
        "
        (click)="navigateTo()"
        >launch</mat-icon
      >
    </div>

    <div class="card-body" style="padding: 12px 6px; align-content: center">
      <div class="d-flex align-items-center justify-content-around">
        <div class="text-center" style="width: 106px">
          <div>
            <h3 class="fw-bold" style="margin-left: -42px">
              Company Documents
            </h3>
          </div>
          <div class="rounded-circle divIconCompany">
            <mat-icon>assignment_ind</mat-icon>
          </div>
        </div>
        <div class="divDocumentCompany">
          <p
            *ngFor="let cardType of getCardTypes()"
            class="mb-0"
            (click)="handleCardClick(cardType)"
            [style.cursor]="cardType.totalCount === 0 ? 'no-drop' : 'pointer'"
          >
            <span class="btnDocumentCategory">{{
              cardType.expirdTypeName
            }}</span>
            <span> : </span>
            <span
              *ngIf="cardType.totalCount !== 0; else noTooltip"
              matTooltip="Company-docs"
              class="expiry-value"
              [ngClass]="getLabelBackground(cardType.expirdType)"
            >
              {{ cardType.totalCount }}
            </span>
            <ng-template #noTooltip>
              <span
                class="expiry-value"
                [ngClass]="getLabelBackground(cardType.expirdType)"
              >
                {{ cardType.totalCount }}
              </span>
            </ng-template>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
