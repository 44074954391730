import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from './jwt.service';
import { Permission } from '../models/permission.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environment/environment';


@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private apiUrl = `${environment.apiUrl}/api/1.0`;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) { }

  getPermissionAsync(): Observable<Permission[]> {
    const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    const roleInfoId = this.tokenService.getRoleInfoIdFromToken();
    const url = `${this.apiUrl}/getPermissionAsync?CompanyInfoId=${companyInfoId}&RoleInfoId=${roleInfoId}`;
    return this.http.get<Permission[]>(url);
  }

  hasPermission(feature: string, permissions: any[]): boolean {
    return permissions.some(permission => permission[feature] === 1);
  }
}
