<div *ngIf="isLoadingRequired">
  <app-loading-animation></app-loading-animation>
</div>
<section>
  <div class="card">
    <div
      class="card-header d-flex justify-content-between"
      style="background-color: rgba(13, 110, 253, 0.2) !important"
    >
      <p class="my-1 fs-6 fw-bolder">Company Documents</p>
      <button
        class="close-icon fs-6 border-0 text-danger fw-bold bg-transparent"
        (click)="onNoClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="card-body p-2">
      <div mat-dialog-content>
        <ng-container *ngIf="isDataAvailable(); else noData">
          <div class="responsive-table">
            <table class="table table-bordered table-hover mb-0">
              <thead class="table-secondary">
                <tr>
                  <th>Document Name</th>
                  <th>Expiry Date</th>
                  <th>Created Date & Time</th>
                  <th>Created By</th>
                  <th>Document</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let doc of data.documentList">
                  <td>{{ doc.documentName }}</td>
                  <td class="text-center">{{ doc.expiryDate | date : "dd-MM-yyyy" }}</td>
                  <td class="text-center">
                    {{ doc.createdDate | date : "dd-MM-yyyy" }} &
                    {{ doc.createdDate | date : "HH:mm" }}
                  </td>
                  <td>{{ doc.createdBy }}</td>
                  <td class="text-center">
                    <ng-container *ngIf="doc.docCode !== 'None'">
                      <button class="border-0 text-primary bg-transparent">
                        <img
                          matTooltip="Document Preview"
                          [src]="getIconUrl(doc.fileExtensionTypeName)"
                          alt="Document Icon"
                          width="30"
                          (click)="downloadFile(doc)"
                        />
                      </button>
                    </ng-container>
                    <ng-container *ngIf="doc.docCode === 'None'">
                      <span class="text-primary">-No File-</span>
                    </ng-container>
                  </td>
                  <td class="text-center">
                    <mat-icon
                      matTooltip="View Company Docs"
                      name="eye-sharp"
                      class="text-primary mat_icon_view ion-icon me-2"
                      (click)="goToCompanyDocuments()"
                      >remove_red_eye</mat-icon
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-template #noData>No data available.</ng-template>
      </div>
    </div>
  </div>
</section>
