<div>
  <mat-icon
    matTooltip="Notification"
    class="notification-icon"
    [matBadge]="alertCount > 0 ? alertCount : null"
    matBadgeColor="warn"
    [matMenuTriggerFor]="belowMenu"
    (click)="toggleDropdown()"
    >notifications</mat-icon
  >

  <mat-menu #belowMenu="matMenu" yPosition="below">
    <div class="px-3 mb-2">
      <p class="notification-header">Notifications</p>
    </div>

    <div *ngIf="employeeAlerts.length; else noDataTemplate">
      <div class="px-3 notification-body" *ngFor="let alert of employeeAlerts">
        <p class="mb-0 py-2">
          <span style="font-weight: 600"
            >{{ alert.firstName }} {{ alert.lastName
            }}<!-- ({{alert.employeeCode}}) --> </span
          >'s
          <span
            *ngFor="let sequence of alert.complianceSequence"
            (click)="
              onCompSequenceClick(
                sequence.compSequenceType,
                alert.firstName,
                alert.lastName,
                alert.employeeId,
                alert.employeeCode
              )
            "
            class="text-primary notification-document"
            >{{ sequence.compSequenceTypeName }},
          </span>
          <span class="notification-text">has been expired!</span>
        </p>
      </div>
    </div>
    <ng-template #noDataTemplate>
      <!-- <mat-spinner
        style="width: 30px; height: 30px; margin: auto"
      ></mat-spinner> -->
      <div class="no-data-message text-center p-3">
        <p>No New Notifications</p>
      </div>
    </ng-template>
  </mat-menu>
</div>
