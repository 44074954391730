<div *ngFor="let response of apiResponse">
  <div class="card card-total-employees">
    <div class="card-body py-1 px-2">
      <div class="d-flex align-items-center justify-content-around">
        <div style="width: 52px">
          <div class="text-primary rounded-circle icon-total-employees-bg">
            <i class="fa fa-users icon-total-employees" aria-hidden="true"></i>
          </div>
        </div>
        <div style="width: 160px">
          <h2 class="card-title">Total Active Employees</h2>
          <div class="d-flex align-items-end">
            <h1 class="mb-0 fw-bold">
              {{ response.employeeCount.totalEmployees }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card card-incomplete-employees mt-2">
    <div class="card-body py-1 px-2">
      <div class="d-flex align-items-center justify-content-around">
        <div style="width: 52px">
          <div class="text-primary rounded-circle icon-incomplete-employees-bg">
            <i
              class="fa fa-id-card icon-incomplete-employees"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <div style="width: 160px">
          <h2 class="card-title">Incomplete Profiles</h2>
          <div class="d-flex align-items-end">
            <h1 class="mb-0 fw-bold">
              {{ response.employeeCount.incompleteProfiles }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card card-complete-employees mt-2">
    <div class="card-body py-1 px-2">
      <div class="d-flex align-items-center justify-content-around">
        <div style="width: 52px">
          <div class="rounded-circle icon-complete-employees-bg">
            <i
              class="fa fa-briefcase icon-complete-employees"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <div style="width: 160px">
          <h2 class="card-title">Complete Profiles</h2>
          <div class="d-flex align-items-end">
            <h1 class="mb-0 fw-bold">
              {{ response.employeeCount.completedProfiles }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card card-inactive-employees mt-2">
    <div class="card-body py-1 px-2">
      <div class="d-flex align-items-center justify-content-around">
        <div style="width: 52px">
          <div class="rounded-circle icon-inactive-employees-bg">
            <i
              class="fa fa-user-slash icon-inactive-employees"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <div style="width: 160px">
          <h2 class="card-title">In-active Employees</h2>
          <div class="d-flex align-items-end">
            <h1 class="mb-0 fw-bold">
              {{ response.employeeCount.inactiveEmployees }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
