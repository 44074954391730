import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Employee } from 'src/app/shared/models/employee.model';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { TokenService } from 'src/app/shared/services/jwt.service';
import { ToastrService } from 'ngx-toastr';
import { EmployeeSelectionService } from '../service/employee-selection.service';
import { LoginAccessType } from '../model/employee.model';
import { DashboardChartService } from '../../dashboard/service/dashboard-chart.service';
import { mobileNoValidator } from 'src/app/shared/validators/mobilenovalidator';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.scss'],
})
export class EmployeeFormComponent implements OnInit {
  employeeList: any[] = [];
  loginAccessTypes: LoginAccessType[] = [];

  employeeForm!: FormGroup;
  loginUserInfoId: number = 0;
  companyInfoId: number = 0;
  roleInfoId: number = 0;
  isLoadingRequired: boolean = false;

  constructor(
    private employeeService: EmployeeService,
    private fb: FormBuilder,
    private tokenService: TokenService,
    private dialogRef: MatDialogRef<EmployeeFormComponent>,
    private toastr: ToastrService,
    private employeeSelectionService: EmployeeSelectionService,
    private dashboardChartService: DashboardChartService
  ) {}

  ngOnInit(): void {
    const token = localStorage.getItem('token');
    if (token) {
      this.loginUserInfoId = this.tokenService.getUserInfoIdFromToken();
      this.companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
      this.roleInfoId = this.tokenService.getRoleInfoIdFromToken();
    } else {
      console.error('Token not found in local storage');
    }

    this.employeeForm = this.fb.group({
      employees: this.fb.array([]),
      loginAccessType: new FormControl(true),
    });

    for (let i = 0; i < 5; i++) {
      this.employeesArray.push(this.createEmployee());
    }

    this.getEmployeeListData();
    this.getLoginAccessType();
  }

  allowNumbersOnly(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  createEmployee(): FormGroup {
    return this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNo: [
        '',
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
          mobileNoValidator(),
        ],
      ],
      loginUserInfoId: [this.loginUserInfoId],
      loginUserRoleInfoId: [this.roleInfoId],
      companyInfoId: [this.companyInfoId],
      loginAccessType: [''],
      roleInfoType: this.fb.array([this.createRoleInfo()]),
    });
  }

  createRoleInfo(): FormGroup {
    return this.fb.group({
      id: [5],
      role: [''],
    });
  }

  getLoginAccessType(): void {
    this.employeeSelectionService.getLoginAccessTypes().subscribe(
      (types) => {
        this.loginAccessTypes = types;
      },
      (error) => {
        console.error('Error fetching login access types:', error);
      }
    );
  }

  get employeesArray(): FormArray {
    return this.employeeForm.get('employees') as FormArray;
  }

  addEmployee(): void {
    const currentLength = this.employeesArray.length;
    const nextIndex = currentLength + 5;

    for (let i = currentLength; i < nextIndex; i++) {
      this.employeesArray.push(this.createEmployee());
    }
  }

  removeEmployee(index: number): void {
    this.employeesArray.removeAt(index);
  }

  getEmployeeListData(): void {
    const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    this.employeeService
      .getEmployeeList(companyInfoId)
      .subscribe((data: any[]) => {
        this.employeeList = data;
      });
  }

  updateLoginAccessTypeForAllEmployees(value: number) {
    const employeesArray = this.employeeForm.get('employees') as FormArray;
    employeesArray.controls.forEach((employee) => {
      employee.get('loginAccessType')?.setValue(value);
    });
  }

  getErrorMessage(index: number): string {
    const employee = this.employeesArray.controls[index];
    const firstName = employee.get('firstName');
    const lastName = employee.get('lastName');
    const mobileNo = employee.get('mobileNo');

    if (!firstName?.value && !lastName?.value && !mobileNo?.value) {
      return 'first name, last name, and mobile number';
    } else if (!firstName?.value) {
      return 'first name';
    } else if (!lastName?.value) {
      return 'last name';
    } else if (!mobileNo?.value) {
      return 'mobile number';
    } else if (mobileNo.value.length < 11) {
      return 'valid mobile number';
    } else {
      return '';
    }
  }

  hasErrors(): boolean {
    for (let i = 0; i < this.employeesArray.length; i++) {
      if (this.showErrorMessage(i)) {
        return true;
      }
    }
    return false;
  }

  // showErrorMessage(index: number): boolean {
  //   const employee = this.employeesArray.controls[index];
  //   const firstName = employee.get('firstName');
  //   const lastName = employee.get('lastName');
  //   const mobileNo = employee.get('mobileNo');

  //   return (
  //     (firstName?.dirty || lastName?.dirty || mobileNo?.dirty) &&
  //     ((!firstName?.value && !lastName?.value && !mobileNo?.value) ||
  //       (!firstName?.value &&
  //         (lastName?.value ||
  //           (mobileNo?.value && mobileNo?.value.length === 11))) ||
  //       (!lastName?.value &&
  //         (firstName?.value ||
  //           (mobileNo?.value && mobileNo?.value.length === 11))) ||
  //       (!mobileNo?.value && (firstName?.value || lastName?.value)) ||
  //       (mobileNo?.value && mobileNo?.value.length !== 11))
  //   );
  // }

  showErrorMessage(index: number): boolean {
    const employee = this.employeesArray.controls[index];
    const firstName = employee.get('firstName');
    const lastName = employee.get('lastName');
    const mobileNo = employee.get('mobileNo');

    const isMobileNoInvalid =
      mobileNo?.dirty && // Check if mobileNo is invalid due to length or not starting with '07'
      (!mobileNo.value ||
        mobileNo.value.length !== 11 ||
        !mobileNo.value.startsWith('07'));

    return (
      (firstName?.dirty || lastName?.dirty || mobileNo?.dirty) &&
      ((!firstName?.value && !lastName?.value && !mobileNo?.value) ||
        (!firstName?.value && (lastName?.value || !isMobileNoInvalid)) ||
        (!lastName?.value && (firstName?.value || !isMobileNoInvalid)) ||
        (!mobileNo?.value && (firstName?.value || lastName?.value)) ||
        isMobileNoInvalid)
    );
  }

  async submitForm(): Promise<void> {
    this.isLoadingRequired = true;
    const loginAccessType = this.employeeForm.get('loginAccessType')?.value
      ? 1
      : 2;

    const formData: Employee[] = this.employeeForm.value.employees
      .filter(
        (employee: any) =>
          employee.firstName && employee.lastName && employee.mobileNo
      )
      .map((employee: any) => ({
        loginUserInfoId: employee.loginUserInfoId,
        loginUserRoleInfoId: employee.loginUserRoleInfoId,
        companyInfoId: employee.companyInfoId,
        firstName: employee.firstName,
        lastName: employee.lastName,
        mobileNo: employee.mobileNo.toString(),
        loginAccessType: loginAccessType,
        roleInfoType: employee.roleInfoType.map((role: any) => ({
          id: role.id,
          role: role.role,
        })),
      }));

    if (formData.length === 0) {
      this.toastr.error('No valid employee data to submit!', 'Error');
      this.isLoadingRequired = false;
      return;
    }

    const mobileNumbers = formData.map((employee) => employee.mobileNo);
    const duplicates = mobileNumbers.filter(
      (item, index) => mobileNumbers.indexOf(item) !== index
    );
    const uniqueDuplicates = [...new Set(duplicates)];

    if (uniqueDuplicates.length > 0) {
      const duplicateNumbersString = uniqueDuplicates.join(', ');
      this.toastr.error(
        `Duplicate mobile numbers found : ${duplicateNumbersString}`
      );
      this.isLoadingRequired = false;
      return;
    }

    try {
      const response: any = await this.employeeService
        .createNewEmployee({ addEmployee: formData })
        .toPromise();

      if (
        response &&
        response.message === 'New employee created successfully'
      ) {
        console.log('Employees created successfully:', response);
        this.employeeSelectionService.newEmployeeAdded.emit();
        this.employeeForm.reset();
        this.dialogRef.close();
        this.dashboardChartService.updatedEmployeeComp.emit();
        this.toastr.success('Employee Created successfully!');
        this.isLoadingRequired = false;
      } else if (
        response &&
        response.message.includes('mobileno already exists')
      ) {
        this.toastr.error(response.message);
        this.isLoadingRequired = false;
        return;
      } else {
        console.error('Unexpected message:', response.message);
        this.toastr.error('Unexpected error occurred');
        this.isLoadingRequired = false;
      }
    } catch (error) {
      console.error('Error occurred while creating employees:', error);
      this.toastr.error('Error occurred while submitting data!', 'Error');
      this.isLoadingRequired = false;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
