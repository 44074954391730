import { Component, OnInit } from '@angular/core';
import {
  Alert,
  ChatAlert,
  EmployeeAlert,
  UpdateAlertRequest,
} from '../../models/notification.model';
import { NotificationService } from '../../services/notification.service';
import { TokenService } from '../../services/jwt.service';
import { Router } from '@angular/router';
import { SignalService } from '../../services/signal.service';
import { TaskDetailService } from 'src/app/dashboard/task-management/task-details/service/task-detail.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  alertCount: number = 0;
  employeeAlerts: EmployeeAlert[] = [];
  chatAlerts: ChatAlert[] = [];
  //showDropdown: boolean = false;
  messageCount: number = 0;
  compSequenceTypeRoutes: { [key: number]: string } = {
    1: '/content/employee-info/employee-details/personal-info',
    2: '/content/employee-info/employee-details/qualification',
    3: '/content/employee-info/employee-details/address',
    4: '/content/employee-info/employee-details/emergency-contact',
    5: '/content/employee-info/employee-details/bank-account',
    6: '/content/employee-info/employee-details/passport',
    7: '/content/employee-info/employee-details/biometric',
    8: '/content/employee-info/employee-details/right-to-work',
    9: '/content/employee-info/employee-details/cos-detail',
    10: '/content/employee-info/employee-details/job-service',
    11: '/content/employee-info/employee-details/offer-letter',
    12: '/others',
    13: '/content/employee-info/employee-details/compliance-document',
  };

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private tokenService: TokenService,
    private signalService: SignalService,
    private taskDetailService: TaskDetailService
  ) {}

  ngOnInit(): void {
    this.fetchDynamicData();
    this.fetchMasterAlerts();
    this.loadChatAlerts();
    this.notificationCount();
  }

  fetchMasterAlerts(): void {
    this.notificationService.getMasterAlerts().subscribe((alerts: Alert[]) => {
      if (alerts && alerts.length > 0) {
        const filteredAlert = alerts.find((alert) => alert.alertType === 1);
        if (filteredAlert) {
          this.alertCount = filteredAlert.alertCount;
        } else {
          this.alertCount = 0;
        }
      }
    });
  }

  fetchDynamicData(): void {
    this.signalService.messages$.subscribe((messageData: any[]) => {
      if (messageData.length > 0) {
        const lastMessage = messageData[messageData.length - 1];
        const currentUserInfoId = this.tokenService.getUserInfoIdFromToken();

        const employeeListIds = lastMessage.employeeListIds || [];
        const notificationHubType = lastMessage.notificationHubType;

        const isCurrentUserInList = employeeListIds.some((employee: any) => {
          console.log('Comparing:', employee.id, 'with', currentUserInfoId);
          return employee.id === +currentUserInfoId;
        });

        if (isCurrentUserInList && notificationHubType === 2) {
          this.loadChatAlerts();
        } else if (isCurrentUserInList && notificationHubType === 1) {
          this.loadChatAlerts();
        } else {
        }
      } else {
        console.log('No message data received from SignalR.');
      }
    });
  }
  loadChatAlerts(): void {
    this.notificationService.getChatAlerts().subscribe((chats: ChatAlert[]) => {
      this.chatAlerts = chats;
      this.messageCount = chats.reduce(
        (sum, alert) => sum + (alert.alertCount || 0),
        0
      );
    });
  }

  navigateToChat(taskInfoId: number, alertType: number): void {
    localStorage.setItem('taskInfoId', taskInfoId.toString());

    if (alertType === 3) {
      localStorage.setItem('ticketDetailsTabIndex', '0');
      this.taskDetailService.updatedHistoryCount.emit();
      this.loadChatAlerts();
    } else if (alertType === 2) {
      localStorage.setItem('ticketDetailsTabIndex', '2');
      this.taskDetailService.updatedCount.emit();
      this.loadChatAlerts();
    }
    this.router.navigate(['/content/task-management/task-list']).then(() => {
      this.router.navigate(['/content/task-management/task-details']);
    });
  }

  toggleDropdown(): void {
    const updateRequest: UpdateAlertRequest = {
      companyInfoId: this.tokenService.getCompanyInfoIdFromToken(),
      loginUserInfoId: this.tokenService.getUserInfoIdFromToken(),
    };

    this.notificationService
      .updateMasterAlert(updateRequest)
      .subscribe((response) => {
        console.log('Update response:', response);
        //this.showDropdown = !this.showDropdown;

        this.notificationService
          .getEmployeeAlerts()
          .subscribe((alerts: EmployeeAlert[]) => {
            this.employeeAlerts = alerts;
          });

        this.fetchMasterAlerts();
      });
  }
  toggleChatMenu(): void {}
  onCompSequenceClick(
    compSequenceType: number,
    firstName: string,
    lastName: string,
    employeeId: number,
    employeeCode: string
  ): void {
    localStorage.setItem('selectedEmployeeCode', employeeCode);
    localStorage.setItem('selectedEmployeeId', employeeId.toString());
    localStorage.setItem('selectedEmployeeLastName', lastName);
    localStorage.setItem('selectedEmployeeFirstName', firstName);

    const route = this.compSequenceTypeRoutes[compSequenceType];
    if (route) {
      this.router.navigate(['/content/employee-info']).then(() => {
        this.router.navigate([route]);
        //this.showDropdown = false;
      });
    }
  }
  notificationCount(): void {
    this.notificationService.noticationCount.subscribe(() => {
      this.loadChatAlerts();
    });
  }
}
