import { Component, OnInit } from '@angular/core';
import {
  Alert,
  EmployeeAlert,
  UpdateAlertRequest,
} from '../../models/notification.model';
import { NotificationService } from '../../services/notification.service';
import { TokenService } from '../../services/jwt.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  alertCount: number = 0;
  employeeAlerts: EmployeeAlert[] = [];
  //showDropdown: boolean = false;

  compSequenceTypeRoutes: { [key: number]: string } = {
    1: '/content/employee-info/employee-details/personal-info',
    2: '/content/employee-info/employee-details/qualification',
    3: '/content/employee-info/employee-details/address',
    4: '/content/employee-info/employee-details/emergency-contact',
    5: '/content/employee-info/employee-details/bank-account',
    6: '/content/employee-info/employee-details/passport',
    7: '/content/employee-info/employee-details/biometric',
    8: '/content/employee-info/employee-details/right-to-work',
    9: '/content/employee-info/employee-details/cos-detail',
    10: '/content/employee-info/employee-details/job-service',
    11: '/content/employee-info/employee-details/offer-letter',
    12: '/others',
    13: '/content/employee-info/employee-details/compliance-document',
  };

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.fetchMasterAlerts();
  }

  fetchMasterAlerts(): void {
    this.notificationService.getMasterAlerts().subscribe((alerts: Alert[]) => {
      if (alerts && alerts.length > 0) {
        this.alertCount = alerts[0].alertCount;
      }
    });
  }

  toggleDropdown(): void {
    const updateRequest: UpdateAlertRequest = {
      companyInfoId: this.tokenService.getCompanyInfoIdFromToken(),
      loginUserInfoId: this.tokenService.getUserInfoIdFromToken(),
    };

    this.notificationService
      .updateMasterAlert(updateRequest)
      .subscribe((response) => {
        console.log('Update response:', response);
        //this.showDropdown = !this.showDropdown;

        this.notificationService
          .getEmployeeAlerts()
          .subscribe((alerts: EmployeeAlert[]) => {
            this.employeeAlerts = alerts;
          });

        this.fetchMasterAlerts();
      });
  }

  onCompSequenceClick(
    compSequenceType: number,
    firstName: string,
    lastName: string,
    employeeId: number,
    employeeCode: string
  ): void {
    localStorage.setItem('selectedEmployeeCode', employeeCode);
    localStorage.setItem('selectedEmployeeId', employeeId.toString());
    localStorage.setItem('selectedEmployeeLastName', lastName);
    localStorage.setItem('selectedEmployeeFirstName', firstName);

    const route = this.compSequenceTypeRoutes[compSequenceType];
    if (route) {
      this.router.navigate(['/content/employee-info']).then(() => {
        this.router.navigate([route]);
        //this.showDropdown = false;
      });
    }
  }
}
