import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCapitializeInput]',
})
export class CapitializeInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input') onInput() {
    let value: string = this.el.nativeElement.value;
    if (value.length > 0) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      this.el.nativeElement.value = value;
    }
  }
}
