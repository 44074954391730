import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitializeInputDirective } from './capitializeinput.directive';
import { UppercaseInputDirective } from './uppercaseinput.directive';
import { LowercaseInputDirective } from './lowercaseinput.directive';
import { CapitializeTextSpaceInputDirective } from './capitializetextspaceinput.directive';
import { NumericInputDirective } from './numericinput.directive';
import { SortCodeDirective } from './sortcode.directive';
import { LimitedSpecialCharactersInputDirective } from './limitedspecialcharacters.directive';
import { AlphaNumericInputDirective } from './alphanumericinput.directive';
import { DisableRightClickDirective } from './disable-right-click.directive';
import { EmailOrMobileInputDirective } from './emailormobileinput.directive';
import { LimitedSpecialCharactersNoSpaceInputDirective } from './limitedspecialcharactersnospace.directive';
import { TrimInputDirective } from './trim-input.directive';

@NgModule({
  declarations: [
    CapitializeInputDirective,
    UppercaseInputDirective,
    LowercaseInputDirective,
    CapitializeTextSpaceInputDirective,
    NumericInputDirective,
    SortCodeDirective,
    LimitedSpecialCharactersInputDirective,
    AlphaNumericInputDirective,
    DisableRightClickDirective,
    EmailOrMobileInputDirective,
    LimitedSpecialCharactersNoSpaceInputDirective,
    TrimInputDirective,
  ],
  imports: [CommonModule],
  exports: [
    CapitializeInputDirective,
    UppercaseInputDirective,
    LowercaseInputDirective,
    CapitializeTextSpaceInputDirective,
    NumericInputDirective,
    SortCodeDirective,
    LimitedSpecialCharactersInputDirective,
    AlphaNumericInputDirective,
    DisableRightClickDirective,
    EmailOrMobileInputDirective,
    LimitedSpecialCharactersNoSpaceInputDirective,
    TrimInputDirective,
  ],
})
export class InputDirectiveModule {}
