import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ComplianceSequence,
  SequenceType,
} from 'src/app/shared/models/compliance-sequence.model';
import { ComplianceSequenceService } from 'src/app/shared/services/compliance-sequence.service';
import { TokenService } from 'src/app/shared/services/jwt.service';
import { Router } from '@angular/router';
import { EmployeeSelectionService } from 'src/app/dashboard/employee-info/service/employee-selection.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { ExpiryDocument } from 'src/app/dashboard/employee-info/model/employee.model';

@Component({
  selector: 'app-employee-layout',
  templateUrl: './employee-layout.component.html',
  styleUrls: ['./employee-layout.component.scss'],
})
export class EmployeeLayoutComponent implements OnInit {
  isDrawerOpen = true; // Open by default on large screens
  showFiller = true;
  roleId!: number;
  complianceSequences: ComplianceSequence[] = [];
  sequenceTypes: SequenceType[] = [];
  companyInfoId: number = 0;
  employeeId: number = 0;
  permissions: any[] = [];
  documents: ExpiryDocument[] = [];
  orderedDocuments: ExpiryDocument[] = [];
  selectedEmployeeFirstName: string | null = localStorage.getItem(
    'selectedEmployeeFirstName'
  );
  selectedEmployeeLastName: string | null = localStorage.getItem(
    'selectedEmployeeLastName'
  );
  selectedEmployeeCode: string | null = localStorage.getItem(
    'selectedEmployeeCode'
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private complianceSequenceService: ComplianceSequenceService,
    private tokenService: TokenService,
    private employeeSelectionService: EmployeeSelectionService,
    public permissionService: PermissionService
  ) {
    this.permissionService.getPermissionAsync().subscribe((permissions) => {
      this.permissions = permissions;
    });
    this.roleId = this.tokenService.getRoleInfoIdFromToken();
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe([
        Breakpoints.Handset, // Mobile devices
      ])
      .subscribe((result) => {
        if (result.matches) {
          // Hide the drawer on mobile devices
          this.isDrawerOpen = false;
        } else {
          // Show the drawer on other devices
          this.isDrawerOpen = true;
        }
      });

    this.employeeId = parseInt(
      localStorage.getItem('selectedEmployeeId') || '0'
    );
    this.getDocumentExpiry();
    this.fetchComplianceSequences();
    this.getSequenceTypes();
    this.updatePersonalInfoOnEvent();
  }

  toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  fetchComplianceSequences() {
    const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
    this.complianceSequenceService
      .getComplianceSequence(companyInfoId, this.employeeId)
      .subscribe(
        (data) => {
          this.complianceSequences = data;
        },
        (error) => {
          console.error('Error fetching compliance sequences:', error);
        }
      );
  }

  getDocumentExpiry(): void {
    this.employeeSelectionService
      .getPendingDocuments()
      .subscribe((data: ExpiryDocument[]) => {
        this.documents = data;

        const mandatoryType1Docs = this.documents.filter(
          (doc) => doc.mandatoryType === 1
        );
        const otherDocs = this.documents.filter(
          (doc) => doc.mandatoryType !== 1
        );
        this.orderedDocuments = [...mandatoryType1Docs, ...otherDocs];
      });
  }
  getBadgeClass(docType: number): string {
    switch (docType) {
      case 1:
        return 'badge-primary';
      case 2:
        return 'badge-success';
      // case 3:
      //   return 'badge-warning';
      default:
        return 'badge-secondary';
    }
  }
  getSequenceTypes() {
    this.complianceSequenceService.getSequenceType().subscribe(
      (data) => {
        this.sequenceTypes = data;
      },
      (error) => {
        console.error('Error fetching sequence types:', error);
      }
    );
  }

  getRouterLink(compSequenceTypeName: string): { link: string; icon: string } {
    let routerLink = '/';
    let icon = '';

    switch (compSequenceTypeName) {
      case 'Personal':
        routerLink = 'personal-info';
        icon = 'person';
        break;
      case 'Education':
        routerLink = 'qualification';
        icon = 'school';
        break;
      case 'Address':
        routerLink = 'address';
        icon = 'home';
        break;
      case 'Emergency Contact':
        routerLink = 'emergency-contact';
        icon = 'contact_phone';
        break;
      case 'Bank Account':
        routerLink = 'bank-account';
        icon = 'account_balance';
        break;
      case 'Passport':
        routerLink = 'passport';
        icon = 'assignment_ind';
        break;
      case 'Biometric':
        routerLink = 'biometric';
        icon = 'badge';
        break;
      case 'Right-to-Work':
        routerLink = 'right-to-work';
        icon = 'chrome_reader_mode';
        break;
      case 'COS Details':
        routerLink = 'cos-detail';
        icon = 'class';
        break;
      case 'Job Contract':
        routerLink = 'job-service';
        icon = 'card_travel';
        break;
      case 'Offer Letter':
        routerLink = 'offer-letter';
        icon = 'assignment_turned_in';
        break;
      case 'Others':
        routerLink = 'other-details';
        icon = 'more_horiz';
        break;
      case 'Compliance Document':
        routerLink = 'compliance-document';
        icon = 'receipt';
        break;
      case 'Hierarchy Chart':
        routerLink = '/content/hierarchy-chart';
        icon = 'insert_chart';
        break;

      default:
        break;
    }
    return { link: routerLink, icon: icon };
  }

  updatePersonalInfoOnEvent(): void {
    this.employeeSelectionService.updatedContentLayout.subscribe(() => {
      const companyInfoId = this.tokenService.getCompanyInfoIdFromToken();
      this.complianceSequenceService
        .getComplianceSequence(companyInfoId, this.employeeId)
        .subscribe(
          (data) => {
            this.complianceSequences = data;
          },
          (error) => {
            console.error('Error fetching compliance sequences:', error);
          }
        );
      this.getDocumentExpiry();
    });
  }
}
