<div class="p-3 text-center">
  <div class="dialog-content">
    <div class="d-flex justify-content-between">
      <h2 class="mb-0">Confirm Action</h2>
      <button
        class="close-icon fs-6 border-0 text-danger bg-white fw-bold"
        (click)="onNoClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="dialog-actions mt-3 p-2 text-start">
    <form [formGroup]="actionForm" (ngSubmit)="onSubmit()">
      <div class="row mb-2">
        <label
          class="form-label p-0 float-start fw-bold"
          for="actionTypeSelect"
        >
          Select Action
        </label>
        <select
          class="form-select"
          formControlName="selectedActionType"
          id="actionTypeSelect"
        >
          <option value="">Select Action</option>
          <option
            *ngFor="let actionType of actionTypes"
            [value]="actionType.key"
            [ngClass]="{ 'disabled-option': actionType.buttonStateType === 0 }"
            [attr.disabled]="actionType.buttonStateType === 0 ? true : null"
          >
            {{ actionType.value }}
          </option>
        </select>
      </div>

      <div class="row">
        <label class="form-label p-0 fw-bold" for="remarks">Remarks</label>
        <textarea
          class="form-control"
          type="text"
          formControlName="remarks"
          id="remarks"
          appCapitializeInput
          appLimitedSpecialCharactersInput
        ></textarea>
      </div>
      <div class="text-center">
        <button class="mt-3 btn btn-sm btn-primary" type="submit">
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
