import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent implements OnInit {
  safeDocumentUrl!: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public documentUrl: string,
    public dialogRef: MatDialogRef<DocumentPreviewComponent>,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    console.log('Document URL:', this.documentUrl);
    // Sanitize the URL
    this.safeDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.documentUrl
    );
    console.log('Safe Document URL:', this.safeDocumentUrl);

    // Wait for the iframe to load and then adjust the image style
    setTimeout(() => {
      const iframe = document.querySelector('iframe') as HTMLIFrameElement;
      if (iframe) {
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow?.document;
        if (iframeDoc) {
          const img = iframeDoc.querySelector('img') as HTMLImageElement;
          if (img) {
            img.style.width = '100%';
            img.style.height = '100%';
            img.style.objectFit = 'contain';
          }
        }
      }
    }, 500); // Adjust the timeout as needed
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
